




























































































































import { Mixins, Component, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import SendDoubts from '@/components/Doubts/SendDoubts/SendDoubts.vue';
import Footer from '@/components/Footer/Footer.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import ModalDisciplineUnavailable from '@/components/Doubts/ModalDisciplineUnavailable/ModalDisciplineUnavailable.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import NewDoubtLoading from './NewDoubtLoading.vue';

import BookService from '@/services/Book/BookService';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import Arrow from '@/assets/icons/Arrow.vue';

import mixinDoubts from '@/mixins/doubts';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_BOOK_ID } from '@/constant/ListBookId';
import { PERMISSION_NAME_BY_DEGREE_ID } from '@/constant/PermissionBookByDegreeID';

import { Book } from '@/globalInterfaces/Book';
import { CountDoubtsToday } from '@/globalInterfaces/Doubts';

import ActiveModal from '@/share/Util/ActiveModal';

import {
  SaveDoubts as ISaveDoubts,
} from '@/services/Doubts/DoubtsInterface';

const QUANTITY_MAX_SEND_DOUBTS_FOR_DISCIPLINE = 3;
const BOOK_REDACTION_SLUG = 'redacao';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    SendDoubts,
    Footer,
    ButtonMovie,
    Arrow,
    NewDoubtLoading,
    ModalDisciplineUnavailable,
    ContainerFluid,
  },
})
export default class NewDoubts extends Mixins(mixinDoubts) {
  private isMobile: boolean = this.$responsive.isMobile;

  private slugBookRedaction = BOOK_REDACTION_SLUG;
  private selectedBookId = '';
  private selectedTopic: number | null = null;
  private quantityDoubtsDay = 0;

  private loadingTopics = false;
  private isLoadingBooks = false;
  private bookSelected = false;
  private isShowFeedbackSelectUser = false;

  private DEFAULT_FILL = DEFAULT_FILL;

  private allBooks: Array<Book> = [];
  private topics: Array<Record<string, any>> = [];

  private BookService = new BookService();
  private modalInstance = new ActiveModal();

  created() {
    this.getBooks();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get isAvailableSend() {
    return (this.getRemainingCreditsDoubts || 0) > 0
        && (this.hasUnlimitedDoubts
        || this.quantityDoubtsDay < QUANTITY_MAX_SEND_DOUBTS_FOR_DISCIPLINE);
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanRedaction() {
    return LIST_PLAN_ID.REDACTION.includes(this.plan?.planID);
  }

  get listBook() {
    if (!this.isPlanRedaction) return this.allBooks;

    const bookRedaction = this.allBooks.find((book: Book) => book?.slug === BOOK_REDACTION_SLUG);

    const newListBook: Array<Book> = this.allBooks.filter((book: Book) => book.slug !== BOOK_REDACTION_SLUG);

    if (bookRedaction) {
      newListBook.unshift(bookRedaction);
    }

    return newListBook;
  }

  async getBooks() {
    try {
      this.isLoadingBooks = true;
      const getAllBooks = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);

      this.allBooks = this.filterBookPermission(getAllBooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar os livros.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingBooks = false;
    }
  }

  filterBookPermission(books: Array<Book>) {
    return books.filter((book: Book) => this.can(PERMISSION_NAME_BY_DEGREE_ID[book?.degreeID]));
  }

  async setChangeSelect(e: any) {
    if (this.isPlanRedaction && Number(e.target.value) !== LIST_BOOK_ID.REDACTION) {
      this.modalInstance.activeModal('ModalDisciplineUnavailable');
      e.target.value = '';
      this.topics = [];
      return;
    }

    this.loadingTopics = true;

    this.bookSelected = true;
    this.isShowFeedbackSelectUser = false;
    this.selectedBookId = e.target.value;

    const topics = await this.BookService.getTopicsBook(Number(this.selectedBookId));

    if (topics.length) {
      this.topics = topics;
    }

    this.loadingTopics = false;
  }

  setChangeSelectTopic(e: any) {
    this.selectedTopic = Number(e.target.value);
  }

  async submitDoubt(content: string) {
    try {
      if (!this.bookSelected) {
        this.isShowFeedbackSelectUser = true;

        return false;
      }

      this.isShowFeedbackSelectUser = false;

      const doubt: ISaveDoubts = {
        content,
        disciplineID: Number(this.selectedBookId),
        userType: 1,
        ...(this.selectedTopic && { topicID: Number(this.selectedTopic) }),
      };

      const response = await this.DoubtsService.saveDoubts(doubt);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {
        this.getQuantityDoubtsToday();

        this.setIsMovie();

        return true;
      }

      return false;
    } catch (error) {
      console.error({ error });

      return false;
    }
  }

  setIsMovie() {
    if (this.$store.getters.isMovie) {
      this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
    }
  }

  handleBack() {
    this.$router.push({ path: '/duvidas' });
  }

  @Watch('selectedBookId', {
    immediate: true,
  })
  async getQuantityDoubtsToday() {
    this.quantityDoubtsDay = 0;

    const response: CountDoubtsToday[] = await this.DoubtsService.getQuantityDoubtsToday();

    if (!response || !response.length) return;

    const foundDiscipline = response.find((countDiscipline: Record<string, any>) => countDiscipline.disciplineID === Number(this.selectedBookId));

    if (foundDiscipline) {
      this.quantityDoubtsDay = foundDiscipline?.count;
    }
  }

  @Watch('$responsive.isMobile')
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Dúvidas', to: '/duvidas' },
      { title: 'Enviar Dúvida', to: null },
    ]);
  }
}
